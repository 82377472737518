<template>
  <div id="app">
    <MainContent/>
  </div>
</template>

<script>
import MainContent from '@/components/MainContent.vue'

export default {
  name: 'App',
  components: {
    MainContent
  }
}
</script>

<style>
</style>
