<template>
  <div class="main">
    <div class="top-contianer" :style="topStyle">
      <div class="big-logo-container" v-if="showBigLogo">
        <div class="big-logo">
          <img
            src="@/assets/img/logo.png"
            alt="广州水熊互娱科技有限公司"
            width="480"
            height="134"
          />
        </div>
        <div class="logo-font-container">
          <span class="red-font">SWIOON</span>
          NETWORK&nbsp;TECHNOLOGY&nbsp;CO.,LTD.
        </div>
      </div>
      <div class="small-logo-container" v-else>
        <img
          src="@/assets/img/logo.png"
          alt="广州水熊互娱科技有限公司"
          width="360"
          height="100"
        />
      </div>
    </div>
    <div class="bottom-container">
      <div class="tabs-container">
        <div class="tabs-list">
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="0"
          >
            首页
          </div>
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="1"
          >
            关于
          </div>
          <div
            class="tabs-item"
            @mouseover="hoverIcon($event)"
            @mouseout="leaveIcon()"
            @click="clickLink($event)"
            data-index="2"
          >
            招聘
          </div>
          <div class="icon-container" :style="style">
            <img
              src="@/assets/img/s.png"
              alt="广州水熊互娱科技有限公司"
              width="16"
            />
          </div>
        </div>
      </div>
      <div class="content-container">
        <div class="content-item" v-if="hoverIndex == 1">
          <div class="about-img">
            <img src="@/assets/img/about.jpg" alt="关于我们" />
          </div>
          <div class="about-font">
            <p>
              广州水熊互娱科技有限公司成立于2018年，是一家集合研发，技术咨询和外包的网络科技公司，
            </p>
            <p>
              公司组建了一支拥有核心技术开发能力并具有丰富网络经验的现代型企业团队，主要干部都是从事网站建设，软件开发，网络营销与推广。
            </p>
            <p>
              公司总部位于广州市天河区，办公环境优雅，氛围轻松亲和，团队以充满活力的90后为主力军。
            </p>
          </div>
        </div>
        <div class="content-item" v-if="hoverIndex == 2">
          <div class="about-img">
            <img src="@/assets/img/recut.jpg" alt="招聘" />
          </div>
          <div class="recruit-list">
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/ui.png" alt="ui" />
              </div>
              <div class="recruit-title">美术(原画/UI)</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/qianduan.png" alt="前端" />
              </div>
              <div class="recruit-title">前端(小游戏/web前端)</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/houduan.png" alt="后端" />
              </div>
              <div class="recruit-title">后端(golang/c#)</div>
            </div>
            <div class="recruit-item">
              <div class="recruit-item-icon">
                <img src="@/assets/img/qianduan.png" alt="u3d" />
              </div>
              <div class="recruit-title">unity3d(c#)</div>
            </div>
          </div>
          <!-- <div class="email-container">简历请发送至邮箱(job@91funny.com)</div> -->
        </div>
      </div>
      <div :class="['web-bottom', hoverIndex > 0 ? 'border' : '']">
        <p>
          &copy;2019-{{y}}&nbsp;广州水熊互娱科技有限公司&nbsp;版权所有
          <a href="http://beian.miit.gov.cn" class="icp-link"
            >粤ICP 备18091970号</a
          >
          <img
            src="@/assets/img/little-logo.png"
            alt="广州水熊互娱科技有限公司"
            width="24"
            class="small-logo"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageIndex",
  data() {
    return {
      style: {},
      topStyle: {},
      hoverIndex: 0,
      showBigLogo: true,
      y: "",
    };
  },
  created() {
    let d = new Date();
    let y = d.getFullYear();

    this.$data.y = y;
  },
  methods: {
    setIconLeft(index) {
      const style = index * 108 + 46; //108为元素宽度 46是初始三角形left的数值
      this.$data.style = { left: `${style}px` };
    },
    hoverIcon(e) {
      const index = e.currentTarget.dataset.index;
      this.setIconLeft(index);
    },
    leaveIcon() {
      this.setIconLeft(this.$data.hoverIndex);
    },
    clickLink(e) {
      const index = e.currentTarget.dataset.index;

      this.$data.hoverIndex = index;
      if (index != 0) {
        this.$data.topStyle = { height: "140px" };
        this.$data.showBigLogo = false;
      } else {
        this.$data.topStyle = { height: "600px" };
        this.$data.showBigLogo = true;
      }

      this.setIconLeft(index);
    },
  },
};
</script>

<style lang="less">
@import "@/assets/css/static.less";

.top-contianer {
  position: relative;
  height: 600px;
  text-align: center;
  overflow: hidden;
  background: #000;
  transition: height 0.3s;
}

.small-logo-container {
  width: 360px;
  height: 100px;
  margin: 20px auto 0 auto;
}

.tabs-container {
  background: #000;
  height: 38px;
  line-height: 32px;
  text-align: center;
}

.web-bottom {
  padding: 70px 0 30px 0;
  color: #333;
  text-align: center;
}

.small-logo {
  margin-left: 10px;
  vertical-align: middle;
}

.icp-link {
  color: #999;
  transition: color 0.3s;
}

.icp-link:hover {
  color: #333;
}

.big-logo-container {
  width: 480px;
  height: 134px;
  margin: 306px auto 0 auto;
}

.logo-font-container {
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
}

.red-font {
  color: #e1794e;
  font-size: 15px;
}

.tabs-list {
  width: 324px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 38px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-item {
  width: 108px;
  font-size: 14px;
  height: 38px;
  cursor: pointer;
  position: relative;
  z-index: 99;
}

.icon-container {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 2;
  bottom: -1px;
  left: 46px;
  transition: all 0.3s;
}

.content-container {
  overflow: hidden;
}

.content-item {
  width: 1190px;
  margin: 50px auto 180px auto;
}

.about-img {
  margin-bottom: 20px;
}

.about-font {
  width: 1000px;
  margin: 0 auto;
  p {
    text-indent: 2em;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    line-height: 30px;
  }
}

.border {
  border-top: 1px solid #333;
}

.recruit-list {
  width: 1190px;
  display: flex;
  justify-content: space-around;
}

.recruit-title {
  font-size: 14px;
  color: #333;
  margin-top: 15px;
  text-align: center;
}

.email-container {
  height: 20px;
  line-height: 20px;
  color: #e1794e;
  font-size: 14px;
  margin-top: 50px;
}

.recruit-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
 